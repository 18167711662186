// Libs.
import React, {useContext} from 'react';
// Assets.
import {AppContext} from "../../layout/AppLayout";

const AppShown = ({children, ...props}) => {
  const app = useContext(AppContext);
  return <> {app && <div data-app="true" {...props} > { children } </div>} </>
};

export default AppShown;
