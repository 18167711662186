// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';

// store
import { useStateValue } from '../../store/state';
//lib
import { useCalculateFMKCartTotals } from '../../libs/fmk/fmk-cart-calculator';
// Deps
import { Link } from 'gatsby';
// Assets.
import styles from './cart-fmk-total.module.scss';
import pageAliases from '../../data/pageAliases';
import locale from '../../locale/locale';
import SiteLink from '../common/site-link';

const CartFmkTotal = ({ checkout, items }) => {
  const [state] = useStateValue();
  const lineItems = items || state.cart.fmk.lineItems;
  const { subtotal, tax, points } = useCalculateFMKCartTotals(lineItems);
  const wrapperClassName = `${styles.cartFmkTotalWrapper} ${
    checkout ? styles.cartFmkTotalWrapperCheckout : styles.cartFmkTotalWrapperOrder
  }`;
  return (
    <div className={wrapperClassName}>
      <div className={styles.cartFmkTotalContainer}>
        <dl>
          <dt>Subtotal:</dt>
          <dd>${subtotal}</dd>
          <dt>GST/HST:</dt>
          <dd>${tax}</dd>
          {points > 0 && (
            <div className={styles.cartFmkTotalPoints}>
              You will earn <strong>{points} pts</strong> with this purchase.
            </div>
          )}
          <dt className={styles.cartFmkTotalWord}>Total:</dt>
          <dd className={styles.cartFmkTotalValue}>
            ${(parseFloat(subtotal) + parseFloat(tax)).toFixed(2)}
          </dd>
        </dl>
        {checkout && (
          <SiteLink
            className={styles.cartFmkTotalCheckout}
            href={pageAliases.festiveMealKitsConfirmOrder}
          >
            {locale.checkoutPage.buttons.checkoutFmkButton}
          </SiteLink>
        )}
      </div>
    </div>
  );
};

CartFmkTotal.propTypes = {
  checkout: PropTypes.bool,
  items: PropTypes.array,
};

export default CartFmkTotal;
