import { isObject, values } from 'lodash';
import { createHash } from 'crypto';

/**
 * Object circular dependency replacer.
 *
 * Resolves: TypeError: Converting circular structure to JSON.
 */
function getCircularReplacer() {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
}

/**
 * Generate unique ID from data (object, array or string).
 *
 * @param {*} data - Data object, array or string.
 * @returns
 */
export function uuidFromData(data) {
  const digestValue = JSON.stringify(data, getCircularReplacer());
  return createHash('md5')
    .update(digestValue)
    .digest('hex');
}
