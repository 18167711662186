import React from 'react';
import { useDishesStaticQuery } from '../hooks/useDishesStaticQuery';

export const useDishesIndex = () => {
  const dishes = useDishesStaticQuery();
  return {
    getEligible() {
      return dishes.filter((item) => {
        return item.eligible;
      });
    },

    getDish(dish, idType = 'crmId') {
      if (typeof dish === 'string') {
        return dishes.find((item) => {
          return item[idType] === dish;
        });
      }
      if (Array.isArray(dish)) {
        return dishes.filter((item) => {
          return dish.includes(item[idType]);
        });
      }
    },

    getAll() {
      return dishes;
    }};
};
