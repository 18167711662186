// Libs.
import React from 'react';
import useForm from 'react-hook-form';
import { useStateValue } from '../../store/state';
import { RemoveDish, RemoveItem } from '../../store/cart/action-creators';
import {has} from 'lodash';
import styles from './fmk-delete.module.scss'


const FMKDelete = ({ data }) => {
  const [,dispatch] = useStateValue()
  const {handleSubmit } = useForm();

  const DeleteItem = () => {
    if (has(data, 'dishCount')) {
      dispatch(RemoveItem({itemId: data.crmId}, 'fmk'));
    } else {
      dispatch(RemoveDish({itemId: data.card.crmId}));
    }
  };


  return  (
    <button className={styles.fmkDelete} name={'item'} onClick={handleSubmit(DeleteItem)}>Remove</button>
  );
};

export default FMKDelete;
