import React, { useContext } from 'react';
import { isObject } from 'lodash';
import * as PropTypes from 'prop-types';
// Libs.
import { useCalculateLoftCart } from '../../libs/class/loft-cart-calculator';

// Assets.
import styles from './order-total.module.scss';
import { AppContext } from '../layout/AppLayout';

const OrderTotal = ({
    showPoints,
    points,
    subTotalLabel,
    appSubTotalLabel,
    taxLabel,
    appTaxLabel,
    totalLabel,
    pointsLabel,
    items,
    totals,
  }) => {
  // Completed orders have totals from Loft, we need to use those instead of
  // re-calculating to prevent price undefined exception due historical orders
  // may have unpublished classes where price cannot be determined anymore.
  if (totals) {
    items = null;
  }

  const totalsData = useCalculateLoftCart(!!items ? items : null);
  const isApp = useContext(AppContext);
  totals = items ? totalsData.totals : totals;

  return (isObject(totals) &&
    <div className={styles.orderTotalContainer} data-app={isApp}>
      <dl>
        <dt>{isApp ? appSubTotalLabel : subTotalLabel}</dt>
        <dd>${totals.subtotal.toFixed(2)}</dd>
        <dt>{isApp ? appTaxLabel : taxLabel}</dt>
        <dd>${totals.tax.toFixed(2)}</dd>
        <hr/>
        <dt>{totalLabel}</dt>
        <dd>${totals.total.toFixed(2)}</dd>
        <hr/>
        {showPoints && points > 0 && (
          <>
            <dt>{pointsLabel}</dt>
            <dd>{points}</dd>
          </>
        )}
      </dl>
    </div>
  );
};

OrderTotal.defaultProps = {
  showPoints: false,
  subTotalLabel: 'Subtotal:',
  appSubTotalLabel: 'Subtotal',
  taxLabel: 'GST/HST:',
  appTaxLabel: 'HST:',
  totalLabel: 'Total:',
  pointsLabel: 'Points earned',
};

OrderTotal.propTypes = {
  points: PropTypes.number,
  showPoints: PropTypes.bool.isRequired,
  subTotalLabel: PropTypes.string,
  taxLabel: PropTypes.string,
  totalLabel: PropTypes.string,
  pointsLabel: PropTypes.string,
  items: PropTypes.array,
  totals: PropTypes.object,
};

export default OrderTotal;
