// Libs.
import React, { memo } from 'react';
import { Link } from 'gatsby';
// Config.
import locale from '../../locale/locale.json';
import { replacePatternWithComponent } from '../../libs/localeUtils';
// Assets.
import styles from './order-conditions.module.scss';
import pageAliases from '../../data/pageAliases';

const OrderConditionsFmk = ({ id }) => {
  const pattern = /(click HERE)/;
  const cancelLink = id ? `${pageAliases.festiveMealKitsOrderDetails}${id}` : pageAliases.contactUs;
  const modificationMessage = replacePatternWithComponent(
    locale.checkoutPage.labels.cancellationFmk,
    pattern,
    <Link to={cancelLink}>click HERE</Link>
  );

  return (
    <div className={styles.orderConditionsContainer}>
      <h3>{locale.checkoutPage.titles.modificationFmk}</h3>
      <p>{locale.checkoutPage.labels.modificationFmk}</p>
      <h3>{locale.checkoutPage.titles.cancellationFmk}</h3>
      <p>{modificationMessage}</p>
      <h3>{locale.checkoutPage.titles.instructionFmk}</h3>
      <p>{locale.checkoutPage.labels.instructionFmk}</p>
    </div>
  );
};

export default memo(OrderConditionsFmk);
