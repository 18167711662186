// Libs.
import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { cloneDeep, isNumber, keyBy, has } from 'lodash';
// Deps.
import OrderDetails from './order-details';
import OrderSummaryLoft from './order-summary-loft';
import CartFMKSummary from '../cart/cart-fmk-summary';
import CartFmkTotal from '../cart/cart-fmk-total';
import OrderTotal from './order-total';
import OrderConditions from './order-conditions';
// Custom Libs.
import {
  addItemsPrice,
  addLineItemsTotal,
  calcLineItemsTotal,
} from '../../libs/class/class-items-calculator';
// Hooks.
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';
// State.
import { useStateValue } from '../../store/state';
import { CompleteOrder } from '../../store/checkout/action-creators';
import { ClearCart } from '../../store/cart/action-creators';
import {
  GroceryHistoryItemsClear,
  ClassHistoryItemsClear,
} from '../../store/myAccount/action-creators';
// Assets.
import styles from './checkout-complete.module.scss';
import successIcon from '../../images/icons/icon-cart-success.svg';
import warningIcon from '../../images/icons/icon-caution-red.svg';
import OrderConditionsFmk from './order-confirmations-fmk';
import { AppContext } from '../layout/AppLayout';
import { addNotification } from '../../libs/notify';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';

const CheckoutComplete = ({ cartType, pageTitle, displayMessage, orderDetails, variation }) => {
  if (!variation || variation === '') variation = 'success';
  const [state, dispatch] = useStateValue();
  // const showPoints = !!state.customer.CardNumber;
  const cartHasItems = state.cart[cartType].lineItems.length > 0;
  const [order] = useState(
    cartHasItems
      ? {
        ...orderDetails,
        orderItems: cloneDeep(state.cart[cartType].lineItems),
      }
      : state.checkout.orderComplete || {},
  );

  const orderItems = order.orderItems || [];
  const earnPoints = isNumber(order.points) ? order.points : 0;
  const [{ all: classes }] = useClassesStaticQuery();
  const classSchedulesById = keyBy(classes, 'nid');
  const classSchedules = orderItems.map((item) => {
    return classSchedulesById[item.id];
  });
  const isApp = useContext(AppContext);

  // Transition cart items into order store.
  useEffect(() => {
    // Only complete order if current cart contains items.
    if (state.cart[cartType].lineItems.length > 0) {
      dispatch(CompleteOrder(order));
      dispatch(ClearCart(cartType));
      if (cartType === 'loft') {
        // Clear order history state to take in effect new order.
        dispatch(ClassHistoryItemsClear());
      } else {
        dispatch(GroceryHistoryItemsClear());
      }
    }
    if (isApp) {
      if (variation === 'success') {
        addNotification(displayMessage);
      } else {
        addNotification(displayMessage, 'error');
      }
    }
  }, []);

  if (!order || !has(order, 'orderItems') || order.orderItems.length === 0) {
    return (
      <div className={styles.checkoutCompleteBackground}>
        <div className={styles.checkoutCompleteContainer}>
          <h1 className={styles.checkoutCompleteTitle}>{pageTitle}</h1>
        </div>
      </div>
    );
  }

  const orderItemSummary = <div className={styles.checkoutCompleteOrderSummary}>
    {cartType === 'loft' && (
      <OrderSummaryLoft
        variation="complete"
        cartItems={order.orderItems}
        classSchedules={classSchedules}
      />
    )}
    {cartType === 'fmk' && <CartFMKSummary variation="complete" items={order.orderItems}/>}
  </div>;
  const orderDetailsAndPoints = <>
    <OrderDetails orderId={order.id} orderDate={order.date}/>
    {cartType === 'loft' && (
      <OrderTotal showPoints={false} points={earnPoints} items={order.orderItems}/>
    )}
    {cartType === 'fmk' && (
      <CartFmkTotal checkout={variation === 'cart'} items={order.orderItems}/>
    )}
  </>;
  const orderPurchaseConditions = cartType === 'fmk' ? (
    state.user.access_token ? <OrderConditionsFmk id={order.id}/> : <OrderConditionsFmk/>
  ) : (
    state.user.access_token ? <OrderConditions id={order.id}/> : <OrderConditions/>
  );

  return (
    <div className={styles.checkoutCompleteBackground} data-app={isApp}>
      <div className={styles.checkoutCompleteContainer}>
        <h1 className={styles.checkoutCompleteTitle}>{pageTitle}</h1>
        {
          !isApp && <p className={styles.checkoutCompleteMessages} data-variation={variation}>
            {variation === 'warning' ? (
              <img src={warningIcon} alt="Warning"/>
            ) : (
              <img src={successIcon} alt="Success"/>
            )}
            <span>{displayMessage}</span>
          </p>
        }
        <AppHidden className={styles.checkoutCompleteFlexContainer}>
          <div className={styles.checkoutCompleteLeftColumn}>
            {orderItemSummary}
            {orderPurchaseConditions}
          </div>
          <div className={styles.checkoutCompleteRightColumn}>
            {orderDetailsAndPoints}
            {orderPurchaseConditions}
          </div>
        </AppHidden>
        <AppShown className={styles.checkoutCompleteFlexContainer}>
          {orderDetailsAndPoints}
          {orderItemSummary}
          {orderPurchaseConditions}
        </AppShown>
      </div>
    </div>
  );
};

CheckoutComplete.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  displayMessage: PropTypes.string.isRequired,
  orderDetails: PropTypes.object.isRequired,
  variation: PropTypes.string,
};

export default CheckoutComplete;
