// Libs.
import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
// Deps.
import LineItemQuantity from './line-item-quantity';
import ErrorMessages from '../../message/error-messages';
import { AppContext } from '../../layout/AppLayout';
// State.
import { useStoresStaticQuery } from '../../../hooks/useStoresStaticQuery';
import useClassLineItemQuantity from '../../../hooks/useClassLineItemQuantity';
// Assets.
import styles from './class-line-item-edit.module.scss';
import { classLineItem as locale } from '../../../locale/locale.json';

const ClassLineItemEdit = ({ item, readOnly, classSchedule, removeCallback }) => {
  const disabled = get(classSchedule, 'availability.booking', false) === false;
  const realAvailability = parseInt(get(classSchedule, 'availability.seats', 0));
  const {
    id,
    name,
    description,
    guests,
    pairing,
    total,
    guestPrice,
    pairingPrice,
    startDate,
    startTime,
    endTime,
  } = item;
  const store = useStoresStaticQuery(get(classSchedule, 'store.nid'));
  const { price, wineCost, withWine, availability } = classSchedule;
  const isApp = useContext(AppContext);

  const lineItemQuantity = useClassLineItemQuantity({
    availableSeats: readOnly ? guests : realAvailability,
    guestCost: price,
    pairingCost: wineCost,
    initGuests: guests,
    initPairing: pairing,
    readOnly,
    classSchedule,
    item,
  });
  let isVirtual = false;
 
  //console.log(item.storeName);
  
  const checkVirtualClass = () => {
    if(item){
      //console.log(selectedStore.name);
      if(item.storeName === "Virtual Class"){
          isVirtual = true;
          return isVirtual;
      }else{
        return isVirtual;
      }
    }
  };
  
  const virtualClass =checkVirtualClass();
  //console.log(virtualClass);
  return (
    <form
      className={styles.classLineItemEditContainer}
      name="booking-form"
      data-readonly={readOnly}
      data-app={isApp}
    >
      {!readOnly && <ErrorMessages messages={lineItemQuantity.errorMessages} />}
      <input type="hidden" name="classId" value={id} />
      <div className={styles.classLineItemEditLineItem} data-disabled={disabled} data-app={isApp}>
        <div className={styles.classLineItemEditTitleWrapper} data-app={isApp}>
          <h2 className={styles.classLineItemEditGuestTitle}>{name}</h2>
          <span>
            {classSchedule.store.title}, {get(store, 'address1')}
          </span>
          <span>
            {!isApp ? description : `${moment(startDate).format('LL')} ${startTime} to ${endTime}`}
          </span>
        </div>
        {(!isApp || (isApp && !readOnly)) && (
          <LineItemQuantity
            key="guests"
            name="guests"
            value={lineItemQuantity.guests}
            setCallback={readOnly ? null : lineItemQuantity.guestChange}
            disabled={disabled}
            isvirtual={virtualClass}
          />
        )}
        {readOnly && isApp && (
          <>
            <div className={styles.classLineItemEditGuestsAndPrices}>
              <span>{guests}</span>
              <span>${guestPrice.toFixed(2)}</span>
            </div>
            <div className={styles.classLineItemEditFinalPrice}>
              ${(guests * guestPrice).toFixed(2)}
            </div>
          </>
        )}
      </div>
      {withWine && (
        <div className={styles.classLineItemEditLineItem} data-disabled={disabled} data-app={isApp}>
          <div className={styles.classLineItemEditTitleWrapper} data-app={isApp}>
            <div className={styles.classLineItemEditPairingTitle}>{locale.titles.winePairing}</div>
            {!isApp && <div className={styles.classLineItemEditPairingSubTitle}>{locale.messages.winePairingAge}</div>}
          </div>
          {(!isApp || (isApp && !readOnly)) && (
            <LineItemQuantity
              key="pairing"
              name="pairing"
              value={lineItemQuantity.pairing}
              setCallback={readOnly ? null : lineItemQuantity.pairingChange}
              disabled={disabled}
            />
          )}
          {isApp && readOnly && (
            <>
              <div className={styles.classLineItemEditGuestsAndPrices}>
                <span>{pairing}</span>
                <span>${pairingPrice.toFixed(2)}</span>
              </div>
              <div className={styles.classLineItemEditFinalPrice}>
                ${(pairing * pairingPrice).toFixed(2)}
              </div>
            </>
          )}
        </div>
      )}
      <div className={styles.classLineItemEditItemFooter}>
        {(!isApp || (isApp && !readOnly)) && <div>${total}</div>}
        {!readOnly && (
          <button onClick={removeCallback} data-id={id}>
            {locale.buttons.remove}
          </button>
        )}
      </div>
      {!readOnly &&
      realAvailability === 0 &&
      availability.status !== 'Cancelled' &&
      availability.status !== 'Registration Closed' && (
        <div className={styles.classLineItemEditSoldOut}>{locale.messages.soldOut}</div>
      )}
    </form>
  );
};

ClassLineItemEdit.defaultProps = {
  readOnly: false,
};

ClassLineItemEdit.propTypes = {
  item: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  classSchedule: PropTypes.object.isRequired,
  removeCallback: PropTypes.func.isRequired,
};

export default ClassLineItemEdit;
