import { sum } from 'lodash';

export const addItemsPrice = (items, classSchedules) => {
  const itemsWithPrice = items.map((item) => {
    const {price = 0, wineCost = 0} = classSchedules[item.id] || {};
    return {
      ...item,
      guestPrice: price,
      pairingPrice: wineCost,
    };
  });

  return itemsWithPrice;
};

export const addLineItemTotal = (item) => {
  const itemWithTotal = {
    ...item,
    total: Number((item.guests || 1) * item.guestPrice + (item.pairing || 0) * item.pairingPrice).toFixed(2),
  };

  return itemWithTotal;
};

export const addLineItemsTotal = (items) => {
  const itemsWithTotal = items.map((item) => {
    return addLineItemTotal(item);
  });

  return itemsWithTotal;
};

export const sumLineItemsTotal = (items) => {
  const itemTotals = items.map((item) => Number(item.total));

  if (itemTotals.length === 0) {
    return 0;
  }

  return sum(itemTotals);
};

export const calcTotalTax = (totalAmount, taxRate = 0.13) => {
  return Number(totalAmount * taxRate);
};

export const calcGrandTotal = (totalAmount, taxAmount) => {
  return sum([totalAmount, taxAmount]);
};

export const calcLineItemsTotal = (itemsWithTotal) => {
  const subtotal = sumLineItemsTotal(itemsWithTotal);
  const tax = calcTotalTax(subtotal);
  const total = calcGrandTotal(subtotal, tax);

  return {
    subtotal: Number(subtotal),
    tax: Number(tax),
    total: Number(total),
  };
};
