import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { has } from 'lodash';
import { formatFMK } from '../libs/formatFromGraph';

export const useFMKStaticQuery = (id = 0, idKey = 'nid') => {
  let fmk = useLoadFMKNodes();

  // Filter by ID.
  if (id) {
    // console.log('prefilter fmk', id, results);
    fmk = fmk.find((node) => node[idKey] === parseInt(id));
    // console.log('filtered fmk', id, results);
    fmk = fmk || {};
  }

  return fmk;
};

/**
 * Load the fmk from graphql for site usage.
 *
 * @returns {Array}
 */
const useLoadFMKNodes = () => {
  const { allNodeProductMealKits } = useStaticQuery(graphql`
    query loadStaticAllfmkList {
      allNodeProductMealKits {
        edges {
          node {
            title
            tax:field_mk_tax_rate
            price:field_mk_unit_cost
            earlyBird: field_early_bird_unit_price
            crmId: field_mk_crm_id
            includes:body {
              value
            }
            dateRange: field_early_bird_period {
              end:end_value
              start:value
            }
            points:field_points_earned_
            notice:field_product_fine_print
            dishCount:field_number_of_free_side_dishes
            nid:drupal_internal__nid
            relationships {
            image:field_meal_kit_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 165) {
                     ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  // const allNodefmk = {};
  return useMemo(() => digestDishNodes(allNodeProductMealKits), ['all']);
};

const digestDishNodes = (loadStaticAllfmkList) => {
  if (!has(loadStaticAllfmkList, 'edges')) {
    return [];
  }
  return loadStaticAllfmkList.edges.map((edge) => formatFMK(edge.node));
};
