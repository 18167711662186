// Libs.
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './cart-fmk-dishes.module.scss';
import CartFMKDishesQuantity from '../cart-fmk-dishes-quantity/cart-fmk-dishes-quantity';
import FMKDelete from '../cart-fmk-dishes-quantity/FMK-delete';
import { get } from 'lodash';

const CartFMKDishes = ({ dishes, variation, avr }) => {
  return (
    <div>
      {dishes.map((dish, index) => {
        return (
          <div className={styles.cartFmkDishesWrap} key={index}>
            <div className={styles.cartFmkDishesImage}>
              <Img fluid={dish.card.image.fluid} />
            </div>
            <div>
              <h2 className={styles.cartFmkDishesItemTitle}>{dish.card.title}</h2>
              <div className={styles.cartFmkDishesItemWeight}>
                <div>{dish.card.description}</div>
              </div>
              <div className={styles.cartFmkDishesDeleteWrapp}>
                ${get(dish, 'card.eligible') ? avr.toFixed(2) : get(dish, 'card.price').toFixed(2)}{' '}
                {variation !== 'view' && variation !== "complete" && <FMKDelete data={dish} />}
              </div>
            </div>
            {['cart', 'view', 'complete'].includes(variation)  &&  <CartFMKDishesQuantity dish={dish} viewOnly={variation !== 'cart'}/>}
          </div>
        );
      })}
    </div>
  );
};

CartFMKDishes.propTypes = {
  dishes: PropTypes.array.isRequired,
};

export default CartFMKDishes;
