// Libs.
import { isEmpty, keyBy } from 'lodash';
// State.
import { useStateValue } from '../../store/state';
import { RemoveItem } from '../../store/cart/action-creators';
// Custom Libs.
import { addItemsPrice, addLineItemsTotal, calcLineItemsTotal } from './class-items-calculator';
// Hooks.
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';

export const useCalculateLoftCart = (items) => {
  const [state, dispatch] = useStateValue();
  const cartItems = items || state.cart.loft.lineItems;
  const [{ all: classes }] = useClassesStaticQuery();
  const classSchedulesById = keyBy(classes, 'nid');

  const cartItemsWithPrice = addItemsPrice(cartItems, classSchedulesById);

  const cartItemsWithTotal = addLineItemsTotal(cartItemsWithPrice);
  const totals = calcLineItemsTotal(cartItemsWithTotal);

  return {
    lineItems: cartItemsWithTotal || 0,
    lineItemsTotals: totals || 0,
    totals: totals || 0
  };
};

