import locale from '../../locale/locale.json';
import { some, get, isArray, isEqual, isObject } from 'lodash';

/**
 * Validate class guests quantity.
 *
 * @param {Number} guests - Guests quantity to set.
 * @param {Number} minGuests - Minimum allowed guests quantity.
 * @param {Number} maxGuests - Max allowed guest quantity.
 * @returns {Object} - Validation result.
 */
export const validateGuest = (guests, minGuests, maxGuests) => {
  // Require minimum guests quantity.
  if (guests < minGuests) {
    return {
      pass: false,
      message: locale.classBooking.errors.guestMinQuantity,
      value: minGuests,
    };
  }

  // Don't allow exceed availability.
  if (guests > maxGuests) {
    // Ensure max availability is not exceeded.
    return {
      pass: false,
      message: locale.classBooking.errors.guestsLimit,
      value: maxGuests,
    };
  }

  return {
    pass: true,
    message: '',
    value: guests,
  };
};

/**
 * Validate class pairing quantity.
 *
 * @param {Number} pairing - Pairing quantity to set.
 * @param {Number} guests - Current guests quantity.
 * @param {Number} maxGuests - Max allowed guests quantity.
 * @returns {Object} - Validation result.
 */
export const validatePairing = (pairing, guests, maxGuests) => {
  // Don't allow pairing greater than guests.
  if (pairing > guests) {
    return {
      pass: false,
      message: locale.classBooking.errors.pairingLimit,
      value: guests,
    };
  }

  if (pairing > maxGuests) {
    // Ensure max availability is not exceeded.
    return {
      pass: false,
      message: locale.classBooking.errors.pairingLimit,
      value: maxGuests,
    };
  }

  return {
    pass: true,
    message: '',
    value: pairing,
  };
};

/**
 * Validate classs schedule availability.
 *
 * The validation of dates / time runs against Gatsby data when item not yet
 * added to cart or against cart item when component is within cart / checkout
 * context and this data is available.
 *
 * @param {Object} classSchedule
 * @param {Object} [cartItem=null]
 *
 * @returns {string[]} Validation error messages array or empty array.
 */
export const validateClassScheduleAvailability = (classSchedule, cartItem = null, context = '') => {
  let errorMessages = [];
  const { availability } = classSchedule;

  const realAvailability = parseInt(
    get(classSchedule, 'availability.seats', classSchedule.availableSeats)
  );

  if (availability.status === 'Cancelled') {
    if (cartItem) {
      errorMessages.push(locale.classLineItem.messages.cancelledPleaseRemove);
    } else {
      errorMessages.push(locale.classLineItem.messages.cancelled);
    }
  } else if (availability.status === 'Registration Closed') {
    if (context === 'booking-modal'){
      errorMessages.push(locale.classLineItem.messages.closed);
    } else if (context === 'cart') {
      errorMessages.push(locale.classLineItem.messages.closedCart);
    } else {
      errorMessages.push(locale.classLineItem.messages.closedCart);
    }
  } else if (realAvailability === 0) {
    errorMessages.push(locale.classLineItem.messages.unavailable);
  } else {
    // When class is bookable and no line item in context, run few more validations.
    if (availability.booking && cartItem === null) {
      if (availability.dateChange === true) {
        errorMessages.push(
          `The date of this class was changed from ${classSchedule.startDateRaw} to ${availability.startDate}`
        );
      }

      if (availability.timeChange === true) {
        errorMessages.push(
          `The time of this class was changed from ${classSchedule.startTime} to ${availability.startTime}`
        );
      }
    }

    // When line item is in context, validate against the cart item state.
    if (availability.booking && isObject(cartItem)) {
      const { guests, startDate, startTime } = cartItem;

      if (guests > realAvailability) {
        errorMessages.push(`Only ${realAvailability} seats are available for this class.`);
      }

      if (availability.startDate && availability.startDate !== startDate) {
        errorMessages.push(
          `The date of this class was changed from ${startDate} to ${availability.startDate}`
        );
      }

      if (availability.startTime && availability.startTime !== startTime) {
        errorMessages.push(
          `The time of this class was changed from ${startTime} to ${availability.startTime}`
        );
      }
    }
  }

  return errorMessages;
};

/**
 * Check when cart itmes date / time is changed.
 *
 * @param {Object[]} classSchedules
 * @param {Object[]} cartItems
 *
 * @returns {bool} Returns true if changes found, false otherwise.
 */
export const checkDateChangeCartItems = (classSchedules, cartItems) => {
  if (!isArray(classSchedules) || !isArray(cartItems)) {
    return false;
  }
  const dateChanges = cartItems.map((cartItem, index) => {
    const classSchedule = classSchedules[index];
    return (
      cartItem.startDate === classSchedule.availability.startDate &&
      cartItem.startTime === classSchedule.availability.startTime
    );
  });

  // Any false, means that date / time changed.
  return some(dateChanges, (value) => value === false);
};
