import { has, sum, map, sumBy, meanBy, get, isNaN, flatten, isEmpty } from 'lodash';
import { useDishesIndex } from '../../services/dishesIndexService';
import { useFmkIndexSingleton } from '../../services/fmkIndexService';

export const useCalculateFMKCartTotals = (lineItems) => {
  const fmkItems = getFMKItemList(lineItems);
  const dishesItems = useGetDishItems(lineItems);
  const eligible = dishesItems.filter((item) => {
    return get(item, 'card.eligible', false);
  });
  eligible.map(item => {
    item.card.avr = avr;
    return item;
  });
  const allDishesFromFmk = flatten(map(fmkItems, (dish) => {
    return dish.dishes.map((item, key) => {
      const newItem = {
        itemId: item.crmId,
        quantity: dish.sides[key]['quantity'],
        card: item,
      };
      return newItem;
    });
  }));
  const eligibleLength = sum(eligible.map((item) => item.quantity));

  const notEligible = dishesItems.filter((item) => {
    return !get(item, 'card.eligible', false);
  });
  const allDishes = [...allDishesFromFmk, ...eligible];
  const avrSum = sumBy(allDishes, (dish) => {
    return (get(dish, 'card.price', 0) * get(dish, 'quantity', 1));
  });
  const avrCount = sumBy(allDishes, (dish) => {
    return get(dish, 'quantity', 1);
  });
  const avr = roundUp(avrSum/avrCount);

  const avrTax = roundUp(meanBy(eligible, (item) => {
    return get(item, 'card.tax', false);
  }));

  const totalItems = roundUp(sumOf(fmkItems, 'card.currentPrice'));
  const totalDishes = roundUp(sumOf(notEligible, 'card.price'));

  const taxItems = roundUp(sumOfTax(fmkItems, 'card.tax'));
  const taxDishes = roundUp(sumOfTax(notEligible, 'card.tax'));
  const subtotal = Number.parseFloat(roundUp(totalItems + (eligibleLength ? (avr*eligibleLength) : 0) + totalDishes)).toFixed(2);
  const pointsItems = roundUp(sumOf(fmkItems, 'card.points'));
  const pointsDishes = roundUp(sumOf(dishesItems, 'card.points'));
  const fixedTax = roundUp(sum([taxItems, taxDishes, avrTax ? roundUp(avrTax * eligibleLength * avr) : 0]));
  const tax = Number.parseFloat(fixedTax).toFixed(2);
  const total = Number.parseFloat(roundUp(totalItems + (eligibleLength ? (avr*eligibleLength) : 0) + (fixedTax + totalDishes))).toFixed(2);
  const points = roundUp(sum([pointsItems, pointsDishes]));

  return { subtotal, total, tax, points, avr };
};

export const useGetDishItems = (lineItems) => {
  const { getDish } = useDishesIndex();

  const dishesItems = lineItems.filter((item) => {
    return !has(item, 'sides');
  });
  dishesItems.map((item) => {
    item.card = getDish(item.itemId);
    return item;
  });

  return dishesItems;
};

export const useGetFMKItems = (lineItems) => {
  const { getFMK } = useFmkIndexSingleton();
  const { getDish } = useDishesIndex();

  const fmkItems = getFMKItemList(lineItems);
  const items = fmkItems.map((item) => {
    item.card = getFMK(item.itemId);
    return item;
  });
  items.map((item) => {
    const dishes = map(item.sides, 'itemId');
    item.dishes = getDish(dishes);
    return item;
  });

  return items;
};

export const useGetFMKItemCount = (lineItems) => {
  const fmkItems = getFMKItemList(lineItems);
  const dishesItems = useGetDishItems(lineItems);

  const countItems = fmkItems.length;
  const countDishes = dishesItems.length;
  const count = sum([countItems, countDishes]);

  return count;
};

const roundUp = (num, decimalPlaces = 2) => {
  const d = decimalPlaces || 0;
  const m = 10 ** d;
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = (f > 0.5 - e && f < 0.5 + e) ? ((i % 2 === 0) ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};

const getFMKItemList = (lineItems) => {
  const fmkItems = lineItems.filter((item) => {
    return has(item, 'sides');
  });
  return fmkItems;
};

const sumOf = (arr, param) => {
  return arr.reduce((sum, item) => {
    const itemTax = get(item, param, 0);
    return sum + (itemTax && !isNaN(itemTax) ? parseFloat(itemTax) : 0) * parseInt(item.quantity);
  }, 0);
};

const sumOfTax = (arr, param) => {
  return (arr.reduce((sum, item) => {
    const taxRate = parseFloat(get(item, param, 0)) || 0;
    const quantity = parseInt(item.quantity);
    let price = parseFloat(get(item, 'card.price', 0));
    // When item has currentPrice, favor it's use due it reflects early bird
    // special price.
    if (has(item, 'card.currentPrice')) {
      price = parseFloat(get(item, 'card.currentPrice', 0));
    }
    const tax = (price * taxRate) * quantity;
    return sum + tax;
  }, 0));
};
