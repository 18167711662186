import { RemoveItem } from '../store/cart/action-creators';

export default function useClassLineItemRemove(dispatch) {
  const handleItemRemove = (e) => {
    e.preventDefault();
    const classId = e.target.dataset.id;
    const item = {
      id: classId,
    };

    dispatch(RemoveItem(item));
  };

  return {
    remove: handleItemRemove,
  };
}
