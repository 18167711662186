import ActionTypes from './action-types';

// Add cart line item.
export const AddItem = (item, type = 'loft') => ({
  type: ActionTypes.ADD,
  payload: item,
  cartType: type,
});

// Remove cart line item.
export const RemoveItem = (item, type = 'loft') => ({
  type: ActionTypes.REMOVE,
  payload: item,
  cartType: type,
});

// Update cart line item.
export const UpdateItem = (item, type = 'loft') => ({
  type: ActionTypes.UPDATE,
  payload: item,
  cartType: type,
});

// Update cart line item.
export const SetCartUrl = (url, type = 'loft') => ({
  type: ActionTypes.CART_URL,
  payload: url,
  cartType: type,
});

// Flush cart items.
export const ClearCart = (type = 'loft') => ({
  type: ActionTypes.CLEAR,
  cartType: type,
});

// Add cart line item.
export const AddDish = (item, type = 'fmk') => ({
  type: ActionTypes.ADD_DISH,
  payload: item,
  cartType: type,
});

// Add cart line item.
export const RemoveDish = (item, type = 'fmk') => ({
  type: ActionTypes.REMOVE_DISH,
  payload: item,
  cartType: type,
});

// Add cart line item.
export const SetPickup = (item, type = 'fmk') => ({
  type: ActionTypes.SET_PICK,
  payload: item,
  cartType: type,
});
