import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { has } from 'lodash';
import { formatNode } from '../libs/formatFromGraph';

export const useDishesStaticQuery = (id = 0, idKey = 'nid') => {
  let Dishes = useLoadDishNodes();

  // Filter by ID.
  if (id) {
    // console.log('prefilter Dishes', id, results);
    Dishes = Dishes.find((node) => node[idKey] === parseInt(id));
    // console.log('filtered Dishes', id, results);
    Dishes = Dishes || {};
  }

  return Dishes;
};

/**
 * Load the Dishes from graphql for site usage.
 *
 * @returns {Array}
 */
const useLoadDishNodes = () => {
  const { allNodeProductMealKitSidesDishes } = useStaticQuery(graphql`
    query loadStaticAllDishesList {
      allNodeProductMealKitSidesDishes {
        edges {
          node {
            title
            nid:drupal_internal__nid
            description:field_size
            price:field_unit_cost
            tax:field_tax_rate
            eligible:field_eligible_side_dish
            crmId:field_crm_id
            relationships {
              type:field_type {
                  name
              }
              image:field_kit_image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxHeight: 810) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  // const allNodeDishes = {};
  return useMemo(() => digestDishNodes(allNodeProductMealKitSidesDishes), ['all']);

};

const digestDishNodes = (allNodeDishe) => {
  if (!has(allNodeDishe, 'edges')) {
    return [];
  }
  return allNodeDishe.edges.map((edge) => formatNode(edge.node));
};
