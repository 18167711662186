// Libs.
import React, {useContext} from 'react';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'gatsby';
// Components.
import ErrorMessages from '../../message/error-messages';
// Custom Libs.
import { validateClassScheduleAvailability } from '../../../libs/class/class-validator';
// Assets.
import styles from './class-line-item-view.module.scss';
import { classLineItem as locale } from '../../../locale/locale.json';
import { AppContext } from '../../layout/AppLayout';

const ClassLineItemView = ({ cartItem, classSchedule, removeCallback }) => {
  const { id, name, description, pairing, total } = cartItem;
  const isApp = useContext(AppContext);
  const { availability } = classSchedule;
  const realAvailability = parseInt(get(classSchedule, 'availability.seats', 0));
  const validationErrors = validateClassScheduleAvailability(classSchedule, cartItem);

  return (
    <div className={styles.classLineItemViewContainer}>
      <ErrorMessages messages={validationErrors} />
      <div className={styles.classLineItemViewLineItem}>
        <div className={styles.classLineItemViewTitleWrapper} data-app={isApp}>
          <h2 className={styles.classLineItemViewGuestTitle}>{name}</h2>
          <span>{cartItem.storeName}</span>
          <span>{description}</span>
        </div>
        <Link className={styles.classLineItemViewEdit} to="/cart">
          Edit
        </Link>
      </div>
      {pairing > 0 && (
        <div className={styles.classLineItemViewLineItem}>
          <div>
            <h3 className={styles.classLineItemViewPairingTitle}>{locale.titles.winePairing}</h3>
            <span>{locale.messages.winePairingAge}</span>
          </div>
        </div>
      )}
      <div className={styles.classLineItemViewItemFooter}>
        <div>${total}</div>
        <button onClick={removeCallback} data-id={id}>
          {locale.buttons.remove}
        </button>
      </div>

      { realAvailability === 0 && availability.status !== 'Cancelled' && availability.status !== 'Registration Closed' && (
        <div className={styles.classLineItemViewSoldOut}>{locale.messages.soldOut}</div>
      )}
    </div>
  );
};

ClassLineItemView.propTypes = {
  cartItem: PropTypes.object.isRequired,
  classSchedule: PropTypes.object.isRequired,
  removeCallback: PropTypes.func.isRequired,
};

export default ClassLineItemView;
