// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './cart-fmk.module.scss'
import FMKDelete from '../cart-fmk-dishes-quantity/FMK-delete';

const CartFMK = ({ fmk, view }) => {

  return (
    <div className={styles.cartFmkContainer}>
      {fmk.map((fmkItem, index) => {
        fmkItem.dishQuantity = fmkItem.sides.map((item) => {
          return item.quantity;
        });
        const item = fmkItem.card;

        return (
          <div key={index} className={styles.cartFmkWrap}>
            <div className={styles.cartFmkImage}>
              <Img fluid={item.image.fluid} />
            </div>
            <div>
              <h2 className={styles.cartFmkItemTitle}>{item.title}</h2>
              <div className={styles.cartFmkDishes}>
                {fmkItem.dishes &&
                fmkItem.dishes.map((dish, i) => (
                  <div key={i}>
                    {dish.title}
                    <span> ({fmkItem.dishQuantity[i]})</span>
                  </div>
                ))}
              </div>
              <div  className={styles.cartFmkDeleteWrapp}>${item.currentPrice} {view && <FMKDelete data={item}/>}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CartFMK.propTypes = {
  fmk: PropTypes.array.isRequired,
};

export default CartFMK;
