import { isString } from 'lodash';
import { parseUrl } from 'query-string';

export function getOrderDetailsFromQueryString(url) {
  let query = {};

  if (typeof window !== 'undefined' && isString(url) && url.length > 0) {
    const parsedUrl = parseUrl(url);
    query = parsedUrl.query || {};
  }

  const orderData = {
    id: query.trnOrderNumber || 'n/a',
    date: query.trnDate || 'n/a',
    points: query.points || 0,
  };

  return orderData;
}
