// Libs.
import React from 'react';
// Custom Libs.
import { uuidFromData } from './uuid';

/**
 * Replace a pattern with a React component within a string.
 *
 * @param {string} text - A string message.
 * @param {RegExp} pattern - A regular expression.
 * @param {Component} Component - A React component that replaces pattern.
 * @returns {Array} - An array with the text chunks mapped to span or desired
 * param component.
 */
export function replacePatternWithComponent(text, pattern, Component) {
  const textChunks = text.split(pattern);
  const matches = text.match(pattern);

  if (textChunks.length <= 1) {
    return text;
  }

  const processedTextChunks = textChunks.reduce((chunks, element) => {
    if (!element) return chunks;
    const uuid = uuidFromData(element);

    if (matches.includes(element)) {
      return [...chunks, <span key={uuid}>{Component}</span>];
    }

    return [...chunks, <span key={uuid}>{element}</span>];
  }, []);

  return processedTextChunks;
}