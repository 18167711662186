// Libs.
import React, {useContext} from 'react';
// Assets.
import {AppContext} from "../../layout/AppLayout";

const AppHidden = ({children, ...props}) => {
  const app = useContext(AppContext);
  return <> {!app && <div data-app="false" {...props} > { children } </div>} </>
};

export default AppHidden;
