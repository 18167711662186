// Libs
import { useState, useEffect } from 'react';
import { defaults, delay, isNaN } from 'lodash';
// State
import { validateClassScheduleAvailability, validateGuest, validatePairing } from '../libs/class/class-validator';
import { UpdateItem } from '../store/cart/action-creators';
import { useStateValue } from '../store/state';

export default function useClassLineItemQuantity(
  {
    availableSeats,
    guestCost,
    pairingCost,
    initGuests = 1,
    initPairing = 0,
    minGuests = 1,
    readOnly = false,
    classSchedule = null,
    item = null,
  },
) {
  const [, dispatch] = useStateValue();
  const [guests, setGuests] = useState(parseInt(initGuests));
  const [pairing, setPairing] = useState(parseInt(initPairing));
  const [guestsError, setGuestError] = useState('');
  const [pairingError, setPairingError] = useState('');
  let errorMessages = [];
  // Prevent Loft API negative availability issue.
  const realAvailability = availableSeats > 0 ? parseInt(availableSeats) : 0;

  // For checks ensuring we have a class, item, and isn't read only.
  const shouldProcessChecks = classSchedule !== null && item !== null && !readOnly;

  // If we should process checks, see if there were availability errors to note.
  if (shouldProcessChecks) {
    errorMessages = errorMessages.concat(validateClassScheduleAvailability(classSchedule, item));
  }

  // Compose error messages.
  if (guestsError) {
    errorMessages.push(guestsError);
  }

  if (pairingError) {
    errorMessages.push(pairingError);
  }

  const handleGuestChange = (value, onBlur = false) => {
    if (value !== '' || onBlur) {
      const currentValue = Number(value === undefined || isNaN(parseInt(value)) ? guests : value) || 0;
      delay(() => {
        const validation = validateGuest(currentValue, minGuests, realAvailability);
        setGuestError(validation.message);
        setGuests(validation.value);
      }, 200);
    } else {
      setGuests(value);
    }
  };

  const handlePairingChange = (value, onBlur = false) => {
    if (value !== '' || onBlur) {
      const currentValue = Number(value === undefined  || isNaN(parseInt(value))  ? pairing : value) || 0;
      delay(() => {
        const validation = validatePairing(currentValue, guests, realAvailability);
        setPairingError(validation.message);
        setPairing(validation.value);
      }, 200);
    } else {
      setPairing(value);
    }
  };

  const composeLineItem = () => {
    return {
      guests,
      pairing,
      guestPrice: guestCost,
      pairingPrice: pairingCost,
    };
  };

  // Cart store update effects on changed guests / pairing through UI that needs
  // to be dispatched to state if quantity changed.
  useEffect(() => {
    if (guests !== "" && !isNaN(parseInt(guests)) && shouldProcessChecks && item.guests !== guests) {
      const updateItem = defaults({ guests }, item);
      dispatch(UpdateItem(updateItem));
    }

    if (guests !== "" && !isNaN(parseInt(guests))) {
      handlePairingChange(pairing);
    }
  }, [guests]);

  useEffect(() => {
    if (pairing !== "" && !isNaN(parseInt(pairing)) &&  shouldProcessChecks && item.pairing !== pairing) {
      const updateItem = defaults({ pairing }, item);
      dispatch(UpdateItem(updateItem));
    }
  }, [pairing]);



  return {
    guests: guests,
    pairing: pairing,
    errorMessages: errorMessages,
    compose: composeLineItem,
    guestChange: handleGuestChange,
    pairingChange: handlePairingChange,
  };
}
