import React from 'react';
import styles from './cart-fmk-summary.module.scss';
// Libs.
import {
  useCalculateFMKCartTotals,
  useGetDishItems,
  useGetFMKItemCount,
  useGetFMKItems,
} from '../../libs/fmk/fmk-cart-calculator';
// Assets.
import FMKSummaryImage from '../../images/GROCERIES-CART-ICON.png';
import { useStateValue } from '../../store/state';
import CartFMK from './cart-fmk';
import CartFMKDishes from './cart-fmk-dishes';

const CartFMKSummary = ({ variation, items }) => {
  const [state] = useStateValue();
  const cartItems = items || state.cart.fmk.lineItems;
  const dishesItems = useGetDishItems(cartItems);
  const fmkItems = useGetFMKItems(cartItems);
  const count = useGetFMKItemCount(cartItems);
  const { total, avr } = useCalculateFMKCartTotals(cartItems);

  return (
    <div
      className={
        variation === 'cart'
          ? styles.cartFmkSummaryFullContainer
          : styles.cartFmkSummaryCheckoutContainer
      }
    >
      <div className={styles.cartFmkSummaryContainer}>
        <div className={styles.cartFmkSummaryHeader}>
          <div className={styles.cartFmkSummaryImage}>
            <img src={FMKSummaryImage} alt="Cart FMK Summary" />
          </div>
          <h2 className={styles.cartFmkSummaryTitle}>Groceries ({count}) </h2>
          <div className={styles.cartFmkSummaryTotal}>${total}</div>
        </div>
        <CartFMK fmk={fmkItems} view={variation !== 'view' && variation !== "complete"} />
        <CartFMKDishes dishes={dishesItems} avr={avr} variation={variation} />
      </div>
    </div>
  );
};

CartFMKSummary.defaultProps = {
  variation: 'cart',
};

export default CartFMKSummary;
