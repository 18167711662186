// Libs.
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import styles from './cart-fmk-dishes-quantity.module.scss';
import minusIcon from '../../images/icons/icon-minus.svg';
import plusIcon from '../../images/icons/icon-plus.svg';
import useForm from 'react-hook-form';
import { useStateValue } from '../../store/state';
import { AddDish } from '../../store/cart/action-creators';
import locale from '../../locale/locale';

const CartFMKDishesQuantity = ({ dish, viewOnly = true }) => {
  const count = parseInt(dish.quantity);
  const [, dispatch] = useStateValue();
  const { register, errors, handleSubmit, setValue } = useForm();
  //console.log(errors);
  const Decrease = (data) => {
    if (data.quantity - 1) {
      dispatch(AddDish({ itemId: dish.itemId, quantity: count - 1 }));
      setValue('quantity', count - 1);
    }
  };
  const Increase = (data) => {
    if (parseInt(data.quantity) + 1 <= 99) {
      dispatch(AddDish({ itemId: dish.itemId, quantity: count + 1 }));
      setValue('quantity', count + 1);
    }
  };
  const Change = (data) => {
    const quantity = parseInt(data.quantity);
    if (quantity <= 99 && quantity > 0) {
      dispatch(AddDish({ itemId: dish.itemId, quantity: quantity }));
    }
  };

  return (
    <form className={styles.cartFmkDishesQuantity}>
      <div className={styles.cartFmkDishesQuantityWrapper}>
        {!viewOnly && <button
          className={styles.cartFmkDishesQuantityButton}
          onClick={handleSubmit(Decrease)}
          ref={register()}
        >
          <img src={minusIcon} alt="Decrease"/>
        </button>}
        <input
          className={styles.cartFmkDishesQuantityInput}
          type={'number'}
          max={99}
          defaultValue={count}
          ref={register({ pattern: { value: /^\d+$/, message: locale.fmkCard.dishError }, min: { value: 1, message: locale.fmkCard.dishError }, max: { value: 99, message: locale.fmkCard.dishError } })}
          name={'quantity'}
          data-error={!!errors['quantity']}
          onChange={handleSubmit(Change)}
          onBlur={handleSubmit(Change)}
          readOnly={viewOnly}
        />
        {!viewOnly && <button className={styles.cartFmkDishesQuantityButton} ref={register()} onClick={handleSubmit(Increase)}>
          <img src={plusIcon} alt="Increase"/>
        </button>}
      </div>
      <div className={styles.formInputError}>
        {errors['quantity'] && <span>{errors['quantity'].message}</span>}
      </div>
    </form>
  );
};

export default CartFMKDishesQuantity;
