// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
// Assets.
import styles from './order-details.module.scss';

const OrderDetails = ({ orderId, orderDate }) => {
  const orderDateFormatted = moment(orderDate, 'MM/DD/YYYY h:mm:ss A').format('MMMM Do YYYY [at] h:mm a');

  return (
    <div className={styles.orderDetailsContainer}>
      <dl>
        <dt>Order Number:</dt>
        <dd>{orderId}</dd>
        <dt>Date:</dt>
        <dd>{orderDateFormatted}</dd>
      </dl>
    </div>
  );
};

OrderDetails.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
};

export default OrderDetails;
