// Libs.
import React, {useContext, useState} from 'react';
import * as PropTypes from 'prop-types';
import { keyBy } from 'lodash';
// Components.
import OrderTotal from './order-total';
// Deps.
import ClassLineItemView from '../common/commerce/class-line-item-view';
import ClassLineItemEdit from '../common/commerce/class-line-item-edit';
// State.
import { useStateValue } from '../../store/state';
import { useCalculateLoftCart } from '../../libs/class/loft-cart-calculator';
// Hooks.
import useClassLineItemRemove from '../../hooks/useClassLineItemRemove';
// Assets.
import styles from './order-summary-loft.module.scss';
import summaryImage from '../../images/LOFT-CART-ICON.png';
import {AppContext} from "../layout/AppLayout";

const OrderSummaryLoft = ({ variation, cartItems, classSchedules }) => {
  const [state, dispatch] = useStateValue();
  const cart = state.cart.loft;
  const count = cartItems.length;
  const { remove } = useClassLineItemRemove(dispatch);
  const { totals, lineItems } = useCalculateLoftCart(cartItems);
  const isApp = useContext(AppContext);
  return (
    <>
      <div className={styles.orderSummaryLoftContainer}>
        {!isApp &&
          <div className={styles.orderSummaryLoftHeader}>
            <div className={styles.orderSummaryLoftImage}>
              <img src={summaryImage} alt="Cart Summary" />
            </div>

            <h2 className={styles.orderSummaryLoftTitle}>Loft classes ({count})</h2>
            <div className={styles.orderSummaryLoftTotal}>${totals.subtotal}</div>
          </div>
        }
        {lineItems.map((cartItem, idx) => {
          return variation === 'complete' ? (
            <ClassLineItemEdit
              key={idx}
              item={cartItem}
              readOnly={true}
              removeCallback={remove}
              classSchedule={classSchedules[idx]}
            />
          ) : (
            <ClassLineItemView
              key={idx}
              cartItem={cartItem}
              classSchedule={classSchedules[idx]}
              removeCallback={remove}
            />
          );
        })}
      </div>
    </>
  );
};

OrderSummaryLoft.propTypes = {
  variation: PropTypes.string.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  classSchedules: PropTypes.arrayOf(PropTypes.object),
};

export default OrderSummaryLoft;
