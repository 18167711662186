// Libs.
import React, {memo, useContext} from 'react';
import { Link } from 'gatsby';
// Config.
import locale from '../../locale/locale.json';
import { replacePatternWithComponent } from '../../libs/localeUtils';
// Assets.
import styles from './order-conditions.module.scss';
import pageAliases from '../../data/pageAliases';
import appAliases from '../../data/appAliases';
import {AppContext} from "../layout/AppLayout";

const OrderConditions = ({ id }) => {
  const isApp = useContext(AppContext);
  const pattern = /(Click Here)/;
  const cancelLink = id ? isApp ? `${appAliases.appAccountProfile}?drawer=transactionHistory_classes&drawer=${id}` : `${pageAliases.accountOrderDetail}${id}` : isApp ? `${appAliases.appOrderComplete}?drawer=Contactus` : pageAliases.contactUs;
  const cancellationMessage = replacePatternWithComponent(
    locale.checkoutPage.labels.cancellationLoft,
    pattern,
    <Link to={cancelLink}>Click Here</Link>
  );

  return (
    <div className={styles.orderConditionsContainer} data-app={isApp}>
      {/*{!isApp &&*/}
        <>
          <h3>{locale.checkoutPage.titles.virtualLoft}</h3>
          <p>{locale.checkoutPage.labels.virtualMessage}</p>
          <h3>{locale.checkoutPage.titles.cancellationLoft}</h3>
          <p>{cancellationMessage}</p>
          <h3>{locale.checkoutPage.titles.allergiesLoft}</h3>
          <p>{locale.checkoutPage.labels.allergiesLoft}</p>
          <h3>{locale.checkoutPage.titles.termsLoft}</h3>
          <p>{locale.checkoutPage.labels.termsLoft}</p>
        </>
      {/*}*/}
    </div>
  );
};

export default memo(OrderConditions);
