import React from 'react';
import { useFMKStaticQuery } from '../hooks/useFMKStaticQuery';

export const useFmkIndexSingleton = () => {
  const fmk = useFMKStaticQuery();

  return {

    getSingle() {
      return fmk.filter((item) => {
        return !item.dishCount;
      });
    },

    getFMK(dish, idType = 'crmId') {
      if (typeof dish === 'string') {
        return fmk.find((item) => {
          return item[idType] === dish;
        });
      }
      if (Array.isArray(dish)) {
        return fmk.filter((item) => {
          return dish.includes(item[idType]);
        });
      }

    },


    getAll() {
      return fmk;
    }
  };
};
