import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
// State
import { AppContext } from '../../layout/AppLayout';
// Custom libs
import { redirectKeyPressHandler } from '../../../libs/accessibility';
// Assets
import plusIcon from '../../../images/icons/icon-plus.svg';
import minusIcon from '../../../images/icons/icon-minus.svg';
import styles from './line-item-quantity.module.scss';

const LineItemQuantity = ({ label, name, value, setCallback, disabled = isvirtual, isvirtual }) => {
  // State
  const isApp = useContext(AppContext);
  // Local state
  const [manualValueFocus, setManualValueFocus] = useState(false);
  // When callback is null render as read only.
  if (setCallback === null) {
    return (
      <div className={disabled ? styles.lineItemQuantityDisabled : null}>
        <div className={styles.lineItemQuantityRowReadonly}>
          <input readOnly type="text" className={styles.lineItemQuantityInput} value={value}/>
        </div>
      </div>
    );
  }

  // Methods
  const lineItemDecreaseQuantity = (e) => {
    e.preventDefault();
    if (!disabled) {
      if (e.currentTarget.name === 'lineItemDecreaseQuantity') {
        const currentValueNumber = Number(value) || 0;
        // Prevent negative numbers.
        if (currentValueNumber > 0) {
          setCallback(currentValueNumber - 1);
        }
      }
    }
  };

  const lineItemIncreaseQuantity = (e) => {
    e.preventDefault();
    if (!disabled) {
      if (e.currentTarget.name === 'lineItemIncreaseQuantity') {
        const currentValueNumber = Number(value) || 0;
        setCallback(currentValueNumber + 1);
      }
    }
  };

  const inputChange = (e) => {
    e.preventDefault();
    // console.log(e.target.value);
    if (!disabled) {
      setCallback(e.target.value);
    }
  };

  // Render
  return (
    <div className={!isvirtual ? styles.lineItemQuantityRow : styles.lineItemQuantityRowVirtual} data-app={isApp}>     
     <label htmlFor={name}>{label}</label>
      <div>
        <button
          name="lineItemDecreaseQuantity"
          className={styles.lineItemQuantityModifier}
          disabled={manualValueFocus}
          onClick={lineItemDecreaseQuantity}
          onKeyPress={redirectKeyPressHandler(lineItemDecreaseQuantity)}
        >
          <img src={minusIcon} alt="Decrease"/>
        </button>
        
        {isvirtual ?
        <input
        readOnly
        onChange={inputChange}
        onKeyPress={redirectKeyPressHandler(inputChange)}
        onFocus={(e) => {
          e.preventDefault();
          setManualValueFocus(true);
        }}
        onBlur={(e) => {
          e.preventDefault();
          setManualValueFocus(false);
          if (!disabled) {
            setCallback(e.target.value, true);
          }
        }}
        type="number"
        className={styles.lineItemQuantityInput}
        value={value}
        min="0"
        step="1"
      />
      :
      <input
        onChange={inputChange}
        onKeyPress={redirectKeyPressHandler(inputChange)}
        onFocus={(e) => {
          e.preventDefault();
          setManualValueFocus(true);
        }}
        onBlur={(e) => {
          e.preventDefault();
          setManualValueFocus(false);
          if (!disabled) {
            setCallback(e.target.value, true);
          }
        }}
        type="number"
        className={styles.lineItemQuantityInput}
        value={value}
        min="0"
        step="1"
      />
        }
        
          
        <button
          name="lineItemIncreaseQuantity"
          className={styles.lineItemQuantityModifier}
          disabled={manualValueFocus}
          onClick={lineItemIncreaseQuantity}
          onKeyPress={redirectKeyPressHandler(lineItemIncreaseQuantity)}
        >
          <img src={plusIcon} alt="Increase"/>
        </button>
      </div>
    </div>
  );
};

LineItemQuantity.defaultProps = {
  label: '',
  description: '',
  value: 0,
};

LineItemQuantity.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setCallback: PropTypes.func,
};

export default LineItemQuantity;
